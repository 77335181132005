import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  ShowButton,
  EditButton,
  DeleteButton,
} from 'react-admin'

const PgyLevelList = props => (
  <List {...props} exporter={false} sort={{ field: 'value', order: 'ASC' }}>
    <Datagrid>
      <TextField source="label" />
      <TextField source="value" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
)

export default PgyLevelList
