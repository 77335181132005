import React from 'react'
import { Datagrid, EditButton, List, ShowButton, TextField } from 'react-admin'
import ChipsTextArrayField from '../../components/ChipsTextArrayField.component'

const CategoryList = props => (
  <List {...props} exporter={false}>
    <Datagrid>
      <TextField source="name" />
      <ChipsTextArrayField source="subCategories" />
      <ShowButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
)

export default CategoryList
