import React from 'react'
import {
  ArrayInput,
  Edit,
  FormDataConsumer,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
} from 'react-admin'
import FormReferenceInput from '../../components/FormReferenceInput.component'
import TextArraySelectInput from '../../components/TextArraySelectInput.component'

const FlashCardUpdate = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <FormReferenceInput
        label="Category"
        source="categoryId"
        reference="Categories"
        validate={[required()]}
      />
      <FormDataConsumer>
        {({ formData }) => (
          <ReferenceInput
            label="Sub Category"
            source="subCategory"
            reference="Categories"
            filter={{ id: formData.categoryId }}
            validate={[required()]}
          >
            <TextArraySelectInput optionValue="subCategories" />
          </ReferenceInput>
        )}
      </FormDataConsumer>
      <TextInput source="question" />
      <ArrayInput source="correctAnswers">
        <SimpleFormIterator>
          <TextInput style={{ width: '90%' }} multiline />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
)

export default FlashCardUpdate
