import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  ShowButton,
  EditButton,
  DeleteButton,
  ReferenceField,
} from 'react-admin'

const AdminList = props => (
  <List {...props} exporter={false}>
    <Datagrid>
      <ReferenceField
        label="Email"
        source="user"
        reference="Users"
        link={false}
      >
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField
        label="Upgraded by"
        source="createdBy"
        reference="Users"
        link={false}
      >
        <TextField source="email" />
      </ReferenceField>
      <TextField source="status" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
)

export default AdminList
