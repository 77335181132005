import React from 'react'
import { Show, SimpleShowLayout, TextField } from 'react-admin'

const PgyLevelShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="label" />
      <TextField source="value" />
    </SimpleShowLayout>
  </Show>
)

export default PgyLevelShow
