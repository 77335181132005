import React from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'

const UserUpdate = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="email" />
      <TextInput source="age" />
      <TextInput source="pgy" />
      <TextInput source="cardsPerDay" />
      <TextInput source="onboardingStatus" />
    </SimpleForm>
  </Edit>
)

export default UserUpdate
