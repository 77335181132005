import React, { useCallback } from 'react'
import { ReferenceInput, SelectInput } from 'ra-ui-materialui'
import { useForm } from 'react-final-form'

const FormReferenceInput = ({ resets, ...props }) => {
  const form = useForm()
  const handleChange = useCallback(() => {
    if (resets) {
      form.change(resets, null)
    }
  }, [resets, form])

  return (
    <ReferenceInput {...props} onChange={handleChange}>
      <SelectInput />
    </ReferenceInput>
  )
}

export default FormReferenceInput
