import React from 'react'
import { Show, SimpleShowLayout, TextField } from 'react-admin'

const UserShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="email" />
      <TextField source="age" />
      <TextField source="cardsPerDay" />
      <TextField source="pgy" />
      <TextField source="onboardingStatus" />
    </SimpleShowLayout>
  </Show>
)

export default UserShow
