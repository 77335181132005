/* eslint-disable import/no-anonymous-default-export */
import List from './list'
import Show from './show'
import Create from './create'
import Update from './update'

export default {
  List,
  Show,
  Create,
  Update,
}
