import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

const PgyLevelCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="label" />
      <TextInput source="value" />
    </SimpleForm>
  </Create>
)

export default PgyLevelCreate
