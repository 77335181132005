import { authProvider } from './index'

const customAuthProvider = {
  ...authProvider,

  login: async params => {
    const response = await authProvider.login(params)
    const claims = await authProvider.getPermissions()

    // Check for admin permissions
    if (claims.admin) {
      return response
    }

    // Make sure user is logged out, if not an admin
    await authProvider.logout()
    throw new Error('You need admin permissions to login.')
  },
}

export default customAuthProvider
