import React from 'react'
import Chip from '@material-ui/core/Chip'

const ChipsTextArrayField = ({ record, source }) => {
  const array = record[source] || []
  if (array.length === 0) {
    return <div />
  }

  return array.map(item => <Chip label={item} key={item} />)
}

export default ChipsTextArrayField
