import firebase from 'firebase/app'
import 'firebase/functions'
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from 'react-admin-firebase'

import config from './config'

// Initialize Firebase application
const app = firebase.initializeApp(config)
if (process?.env.REACT_APP_USE_FUNCTIONS_EMULATOR === 'true') {
  // Enable to connect with local functions emulator
  app.functions().useEmulator('localhost', 5001)
}

const options = {
  app,
  dontAddIdFieldToDoc: true,
  firestoreCostsLogger: {
    enabled: true,
  },
  lazyLoading: {
    enabled: false,
  },
  logging: true,
}

// Export Firebase Admin default Auth provider
export const authProvider = FirebaseAuthProvider(config, options)

// Export Firebase Admin default Data provider
export const dataProvider = FirebaseDataProvider(config, options)
