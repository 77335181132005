import firebase from 'firebase/app'
import { dataProvider } from './index'
import {
  FLASHCARDS_RESOURCE,
  USER_RESOURCE,
  ADMINS_RESOURCE,
} from '../constants/appConstants'

const customDataProvider = {
  ...dataProvider,

  delete: async (resource, params) => {
    try {
      if (resource === FLASHCARDS_RESOURCE) {
        const res = await dataProvider.update(resource, {
          ...params,
          data: { disabled: true },
        })
        return res
      }
      if (resource === USER_RESOURCE || resource === ADMINS_RESOURCE) {
        const isAdminResource = resource === ADMINS_RESOURCE
        const shouldRevokeAdminAccess =
          params.previousData.admin || isAdminResource

        if (shouldRevokeAdminAccess) {
          const userId = isAdminResource
            ? params.previousData.user
            : params.previousData.id
          const revokeAdminAccess = firebase
            .functions()
            .httpsCallable('revokeAdminAccess')
          await revokeAdminAccess({
            ...params.previousData,
            userId,
          })
        }

        const disableUserAccount = firebase
          .functions()
          .httpsCallable('disableUserAccount')

        await disableUserAccount({ userId: params.id })
        return { data: { ...params, disabled: true } }
      }

      const res = await dataProvider.delete(resource, params)
      return res
    } catch (err) {
      const message = err
        ? `${err.code}: ${err.message}`
        : 'An unexpected error occurred, please try again later'
      throw new Error(message)
    }
  },

  getList: async (resource, params) => {
    if (resource === ADMINS_RESOURCE) {
      const listAllAdmins = firebase.functions().httpsCallable('listAllAdmins')
      const res = await listAllAdmins()
      const data = res.data.map((user, i) => ({ ...user, id: i }))

      return { data, total: data.length }
    }

    return dataProvider.getList(resource, params)
  },

  create: async (resource, params) => {
    try {
      if (resource === ADMINS_RESOURCE) {
        const createAdmin = firebase.functions().httpsCallable('createAdmin')
        const { data } = await createAdmin(params.data)

        return { data: { ...params.data, id: data.userId } }
      }
      if (resource === USER_RESOURCE) {
        const { data: formData } = params
        const password = formData.password.trim()
        const authResponse = await firebase
          .auth()
          .createUserWithEmailAndPassword(
            formData.email,
            password || process?.env.REACT_APP_DEFAULT_USER_PASSWORD,
          )
        const userId = authResponse?.user?.uid
        await firebase
          .firestore()
          .collection(USER_RESOURCE)
          .doc(userId)
          .set(formData, { merge: true })

        return { data: { ...formData, id: userId } }
      }

      if (resource === FLASHCARDS_RESOURCE) {
        params.data.disabled = false
      }

      return await dataProvider.create(resource, params)
    } catch (err) {
      const message = err
        ? `${err.code}: ${err.message}`
        : 'An unexpected error occurred, please try again later'
      throw new Error(message)
    }
  },

  makeAdmin: async (resource, params) => {
    try {
      const grantAdminAccess = firebase
        .functions()
        .httpsCallable('grantAdminAccess')
      const res = await grantAdminAccess({ userId: params.id })

      return res.data
    } catch (err) {
      const message = err
        ? `${err.code}: ${err.message}`
        : 'An unexpected error occurred, please try again later'
      throw new Error(message)
    }
  },
}

export default customDataProvider
