import React from 'react'
import { SelectInput } from 'ra-ui-materialui'

const TextArraySelectInput = ({ choices, optionValue, ...props }) => {
  const items = []
  choices.forEach(choice => {
    const array = choice[optionValue] || []
    array.forEach(item => {
      items.push({
        id: item,
        name: item,
      })
    })
  })

  return <SelectInput {...props} choices={items} />
}

export default TextArraySelectInput
