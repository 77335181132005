import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SimpleFormIterator,
  ArrayInput,
  Toolbar,
  SaveButton,
} from 'react-admin'

const PostEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const CategoryUpdate = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<PostEditToolbar />}>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <ArrayInput source="subCategories">
        <SimpleFormIterator disableRemove>
          <TextInput disabled />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
)

export default CategoryUpdate
