import React from 'react'
import { Show, SimpleShowLayout, TextField } from 'react-admin'
import TextArrayField from '../../components/TextArrayField.component'

export const FlashCardShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="categoryId" />
      <TextField source="question" />
      <TextArrayField source="correctAnswers" />
    </SimpleShowLayout>
  </Show>
)

export default FlashCardShow
