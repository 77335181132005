import React from 'react'
import { Button, useRecordContext, useNotify } from 'react-admin'
import FlagIcon from '@material-ui/icons/Flag'
import dataProvider from '../firebase/customDataProvider'
import { USER_RESOURCE } from '../constants/appConstants'

const FlagButton = props => {
  const record = useRecordContext(props)
  const notify = useNotify()
  const onClick = e => {
    e.stopPropagation()
    dataProvider
      .makeAdmin(USER_RESOURCE, record)
      .then(() => notify(`Granted admin access to: ${record.email}`))
  }

  return (
    <Button onClick={onClick} disabled={record.admin}>
      <FlagIcon />
    </Button>
  )
}

export default FlagButton
