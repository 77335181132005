import React from 'react'
import { Admin, Resource } from 'react-admin'
import CategoryIcon from '@material-ui/icons/Category'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SchoolIcon from '@material-ui/icons/School'
import UserIcon from '@material-ui/icons/People'

import CategoriesResource from './resources/Categories'
import FlashCardsResource from './resources/FlashCards'
import PgyLevelsResource from './resources/PgyLevels'
import UsersResource from './resources/Users'
import AdminsResource from './resources/Admins'

import customAuthProvider from './firebase/customAuthProvider'
import customDataProvider from './firebase/customDataProvider'

import {
  FLASHCARDS_RESOURCE,
  PGY_RESOURCE,
  CATEGORIES_RESOURCE,
  USER_RESOURCE,
  ADMINS_RESOURCE,
} from './constants/appConstants'

import './overrides'

const App = () => (
  <Admin dataProvider={customDataProvider} authProvider={customAuthProvider}>
    <Resource
      name={FLASHCARDS_RESOURCE}
      list={FlashCardsResource.List}
      show={FlashCardsResource.Show}
      create={FlashCardsResource.Create}
      edit={FlashCardsResource.Update}
      icon={DashboardIcon}
    />
    <Resource
      name={PGY_RESOURCE}
      list={PgyLevelsResource.List}
      show={PgyLevelsResource.Show}
      create={PgyLevelsResource.Create}
      edit={PgyLevelsResource.Update}
      icon={SchoolIcon}
    />
    <Resource
      name={CATEGORIES_RESOURCE}
      list={CategoriesResource.List}
      show={CategoriesResource.Show}
      create={CategoriesResource.Create}
      edit={CategoriesResource.Update}
      icon={CategoryIcon}
    />
    <Resource
      name={USER_RESOURCE}
      list={UsersResource.List}
      show={UsersResource.Show}
      create={UsersResource.Create}
      edit={UsersResource.Update}
      icon={UserIcon}
    />
    <Resource
      name={ADMINS_RESOURCE}
      list={AdminsResource.List}
      show={AdminsResource.Show}
      create={AdminsResource.Create}
      edit={AdminsResource.Update}
    />
  </Admin>
)
export default App
