import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  ShowButton,
  EditButton,
  DeleteButton,
  Filter,
  TextInput,
} from 'react-admin'
import FlagButton from '../../components/FlagButton.component'

const UsersFilter = props => (
  <Filter {...props}>
    <TextInput label="Search user by email" source="email" alwaysOn />
  </Filter>
)

const UserList = props => (
  <List
    {...props}
    exporter={false}
    filters={<UsersFilter />}
    filter={{ disabled: false }}
  >
    <Datagrid>
      <TextField source="email" />
      <TextField source="age" />
      <TextField source="pgy" />
      <ShowButton label="" />
      <FlagButton />
      <EditButton label="" />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
)

export default UserList
