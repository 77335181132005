import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'

const TextArrayField = ({ record, source }) => {
  const array = record[source] || []

  if (array.length === 0) {
    return <div />
  }

  return array.map(item => (
    <Fragment key={item}>
      <Typography component="span" variant="body2">
        {`${item}\n`}
      </Typography>
    </Fragment>
  ))
}

export default TextArrayField
