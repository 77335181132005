import React from 'react'
import {
  ArrayInput,
  Create,
  FormDataConsumer,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
} from 'react-admin'
import TextArraySelectInput from '../../components/TextArraySelectInput.component'
import FormReferenceInput from '../../components/FormReferenceInput.component'

const FlashCardCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <FormReferenceInput
        label="Category"
        source="categoryId"
        reference="Categories"
        resets="subCategory"
        validate={[required()]}
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.categoryId && (
            <ReferenceInput
              label="Sub Category"
              source="subCategory"
              reference="Categories"
              filter={{ id: formData.categoryId }}
              validate={[required()]}
            >
              <TextArraySelectInput optionValue="subCategories" />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <TextInput source="question" />
      <ArrayInput source="correctAnswers">
        <SimpleFormIterator>
          <TextInput style={{ width: '90%' }} multiline />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
)

export default FlashCardCreate
