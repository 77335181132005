import React from 'react'
import { Show, SimpleShowLayout, TextField } from 'react-admin'
import ChipsTextArrayField from '../../components/ChipsTextArrayField.component'

const CategoryShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <ChipsTextArrayField source="subCategories" />
    </SimpleShowLayout>
  </Show>
)

export default CategoryShow
